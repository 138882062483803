import styled from 'styled-components'
import { ThemeColor } from '../../themes'

type Props = {
  color?: ThemeColor
}

export const Text = styled.div<Props>`
  font-size: 0.75rem;
  font-weight: 700;
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.typePrimary)};
`
