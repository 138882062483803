import { FC } from 'react'
import { Container, Content } from './LayoutContent.style'

const LayoutContent: FC<{ children?: React.ReactNode }> = ({ children }) => (
  <Container>
    <Content>{children}</Content>
  </Container>
)

export default LayoutContent
