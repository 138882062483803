import styled from 'styled-components'
import { zIndex } from '../zIndex'

export const CornerRibbon = styled.div`
  position: fixed;
  top: 0.9375rem;
  left: -1.875rem;
  width: 7.5rem;
  transform: rotate(-45deg);
  text-align: center;
  line-height: 1.875rem;
  background: #000;
  color: #fff;
  z-index: ${zIndex.cornerRibbon};
`
