import { default as Link } from 'next/link'
import { FC } from 'react'
import { noop } from '@netpurpose/utils'
import { ThemeColor } from '../../themes'
import { StyledAnchor } from './NextLink.style'

type Props = {
  href: string
  children: React.ReactNode
  external?: boolean
  className?: string
  title?: string
  onClick?: () => void
  testId?: string
  scroll?: boolean
  hoverColor?: ThemeColor | undefined
  bold?: boolean
}

export const NextLink: FC<Props> = ({
  children,
  href,
  external = false,
  className,
  title,
  onClick,
  testId,
  hoverColor,
  bold = true,
  ...props
}) =>
  external ? (
    <StyledAnchor
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
      title={title || ''}
      onClick={onClick}
      data-testid={testId}
      $hoverColor={hoverColor}
      $bold={bold}
    >
      {children}
    </StyledAnchor>
  ) : (
    <Link
      href={href}
      className={className}
      onClick={onClick || noop}
      data-testid={testId}
      {...props}
    >
      {children}
    </Link>
  )
