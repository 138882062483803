import { Layout as AntLayout } from 'antd'
import { FC } from 'react'
import LayoutContent from './LayoutContent'

const Layout: FC<{ children?: React.ReactNode }> &
  Omit<typeof AntLayout, 'Content'> & {
    Content: typeof LayoutContent
  } = AntLayout

LayoutContent.displayName = 'Layout.Content'
Layout.Content = LayoutContent

export default Layout
