import { Menu as AntMenu, MenuProps } from 'antd'
import styled from 'styled-components'

export type MenuButtonSize = 'small' | 'medium' | 'large'

type Props = {
  items: MenuProps['items']
  $menuButtonSize: MenuButtonSize
  $scrollable: boolean
  $removePadding?: boolean
}

export const CalloutMenuContainer = styled(AntMenu)<Props>`
  ${({ $scrollable }) =>
    $scrollable ? 'overflow-y: scroll; height: 100%; max-height: 100vh; position: fixed' : ''}

  &.ant-menu-root.ant-menu-vertical {
    width: 9.25rem;
    padding: 0;
    background-color: ${({ theme }) => theme.colors.bgSecondary};
    border-radius: ${({ theme }) => theme.border.radius};
    box-shadow: ${({ theme }) => theme.shadow.regular};
  }

  &.ant-dropdown-menu {
    padding: 0;
    border: 1px solid ${({ theme }) => theme.colors.architecture3};
    border-radius: ${({ theme }) => theme.border.radiusSmall};
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  }

  .ant-dropdown-menu-item-divider {
    background-color: ${({ theme }) => theme.colors.architecture3};
  }

  ul.ant-dropdown-menu-item-group-list {
    margin: 0;

    li {
      margin: 0;
    }
  }

  .ant-dropdown-menu-item-group-title {
    color: ${({ theme }) => theme.colors.typePrimary};
    background-color: ${({ theme }) => theme.colors.bgPrimary};
    font-weight: bold;
    font-size: 0.75rem;
    line-height: 1rem;
    padding: 0.5rem 0.75rem;
    border-radius: ${({ theme }) => theme.border.radiusSmall}
      ${({ theme }) => theme.border.radiusSmall} 0 0;
  }

  .ant-dropdown-menu-item {
    padding: ${({ $removePadding, $menuButtonSize }) => {
      if ($removePadding) {
        return '0'
      }
      if ($menuButtonSize === 'large') {
        return '0.75rem 2rem'
      }
      if ($menuButtonSize === 'medium') {
        return '0.75rem 0.75rem; line-height: 1rem;'
      }
      return '0.3125rem 0.75rem'
    }};

    &:first-child {
      border-radius: ${({ theme }) => theme.border.radiusSmall}
        ${({ theme }) => theme.border.radiusSmall} 0 0;
    }

    &:last-child {
      border-radius: 0 0 ${({ theme }) => theme.border.radiusSmall}
        ${({ theme }) => theme.border.radiusSmall};
    }

    &:first-child:last-child {
      border-radius: ${({ theme }) => theme.border.radiusSmall};
    }
  }
`

export const CalloutMenuItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* Ensures text and icons aren't right up against each other. */
  column-gap: 1rem;
  white-space: nowrap;
  /* for some reason, antd disables pointer events and so this is to make sure */
  /* tests pass when clicking on menu items */
  pointer-events: auto;

  &.ant-dropdown-menu-item {
    overflow: revert;
  }

  .ant-dropdown-menu-title-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`
