import { Layout as AntLayout } from 'antd'
import styled from 'styled-components'

const { Content: AntContent } = AntLayout

export const Container = styled(AntContent)`
  display: flex;
  justify-content: center;
  /* Ensure takes up entire height of screen minus navbar */
  min-height: ${({ theme }) => `calc(100vh - ${theme.sizing.navbarHeight})`};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
