import { FC, ReactNode } from 'react'
import { Spinner } from '@netpurpose/np-ui'
import { LoggedInLayout } from '#components/LoggedInLayout'

type Props = {
  isAuthorised: boolean | undefined
  children: ReactNode
  title: string
}

export const ShowIfAuthorised: FC<Props> = ({ isAuthorised, children, title }) => {
  if (isAuthorised) {
    return <LoggedInLayout title={title}>{children}</LoggedInLayout>
  }

  if (typeof isAuthorised === 'undefined') {
    return <Spinner />
  }

  return (
    <div>You&apos;re not authorised to see this page. Contact your manager to request access.</div>
  )
}
