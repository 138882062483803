import { MenuProps } from 'antd'
import { NextRouter, useRouter } from 'next/router'
import { FC } from 'react'
import { useLoggedIn } from '@netpurpose/core'
import { Navbar as NPNavbar } from '@netpurpose/np-ui'
import { PermissionsResourceType } from '@netpurpose/types'
import { logout } from '#auth'
import { routes } from '#config'

const getSelectedRouteName = (router: NextRouter): string => {
  // Splitting on / to make sure nested paths still highlight the right parent route in menu as selected
  const routeSections = router.pathname.split('/')

  // Need special handling for the batches tab as it is at /collection/batches
  if (routeSections[1] === 'collection') {
    return routeSections[2] === 'batches' ? '/batches' : '/collection'
  }

  return `/${routeSections[1]}`
}

export const Navbar: FC = () => {
  const { user } = useLoggedIn()
  const router = useRouter()

  const items = [
    user?.canAccessFacts && {
      label: <NPNavbar.NavbarLink displayText="Facts" route={routes.facts} />,
      key: '/facts',
    },
    user?.canAccessMetrics && {
      label: <NPNavbar.NavbarLink displayText="Metrics" route={routes.metrics} />,
      key: '/metrics',
    },
    user?.canAccessKpis && {
      label: <NPNavbar.NavbarLink displayText="KPIs" route={routes.kpis} />,
      key: '/kpis',
    },
    user?.canAccessUnits && {
      label: <NPNavbar.NavbarLink displayText="Units" route={routes.units} />,
      key: '/units',
    },
    user?.canAccessEntities && {
      label: <NPNavbar.NavbarLink displayText="Entities" route={routes.entities} />,
      key: '/entities',
    },
    user?.canAccessSources && {
      label: <NPNavbar.NavbarLink displayText="Sources" route={routes.sources} />,
      key: '/sources',
    },
    user?.canAccessEstimations &&
      user.canAccessTechnologies && {
        label: <NPNavbar.NavbarLink displayText="PSI" route={routes.psi} />,
        key: '/psi',
      },
    user?.canAccessLookup && {
      label: <NPNavbar.NavbarLink displayText="Lookup" route={routes.lookup} />,
      key: '/lookup',
    },
    user?.canAccessCreate && {
      label: <NPNavbar.NavbarLink displayText="Create" route={routes.create} />,
      key: '/create',
    },
    user?.canAccessUsers && {
      label: <NPNavbar.NavbarLink displayText="Users" route={routes.users} />,
      key: '/users',
    },
    user?.canAccessGroups && {
      label: <NPNavbar.NavbarLink displayText="Groups" route={routes.groups} />,
      key: '/groups',
    },
    user?.canAccessSegments && {
      label: <NPNavbar.NavbarLink displayText="Segments" route={routes.segments} />,
      key: '/segments',
    },
    user?.canExtract && {
      label: <NPNavbar.NavbarLink displayText="Collection" route={routes.collectionStart} />,
      key: '/collection',
    },
    user?.hasPerm(PermissionsResourceType.Batch, 'LIST') && {
      label: <NPNavbar.NavbarLink displayText="Batches" route={routes.collectionBatches} />,
      key: '/batches',
    },
    user?.hasPerm(PermissionsResourceType.Source, 'SEARCH') && {
      label: <NPNavbar.NavbarLink displayText="Search" route={routes.semanticSearch} />,
      key: '/semantic-search',
    },
    user?.hasPerm(PermissionsResourceType.EstimationsData, 'LIST') && {
      label: <NPNavbar.NavbarLink displayText="Graph" route={routes.datapointGraph} />,
      key: routes.datapointGraph,
    },
  ].filter((item) => !!item) as MenuProps['items']
  // NOTE: using type assertion here as the filter method is a known bug to not filter out falsy values from types
  // https://github.com/microsoft/TypeScript/issues/16069

  return (
    <NPNavbar
      logoLink="/"
      logout={() => logout(router)}
      isAuthenticated={!!user}
      getSelectedRouteName={() => getSelectedRouteName(router)}
      {...(user?.firstName ? { userDisplayName: user?.firstName } : {})}
      items={items}
    />
  )
}
