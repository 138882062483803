import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import Head from 'next/head'
import { FC, ReactNode } from 'react'
import styled from 'styled-components'
import { CornerRibbon, Layout } from '@netpurpose/np-ui'
import { isDevEnv, isProductionEnv } from '@netpurpose/utils'
import { AlertBanner } from '#components/common/AlertBanner'
import { Navbar } from '#components/common/Navbar'
import { ENV } from '#config'

const Container = styled.div`
  margin: 0;
  padding: 0;
`

type Props = {
  children: ReactNode
  title: string
}

export const LoggedInLayout: FC<Props> = ({ children, title }) => (
  <>
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>{`${title} - Net Purpose Control Tool`}</title>
    </Head>
    <Container>
      <Layout>
        {isDevEnv() && <ReactQueryDevtools />}
        {isProductionEnv() ? null : <CornerRibbon>{ENV}</CornerRibbon>}
        <Navbar />
        <AlertBanner />
        <Layout.Content>{children}</Layout.Content>
      </Layout>
    </Container>
  </>
)
