import { MenuProps } from 'antd'
import { FC } from 'react'
import { CalloutMenuContainer, MenuButtonSize } from './CalloutMenu.style'
import CalloutMenuItem from './CalloutMenuItem'

type SubComponents = {
  Item: typeof CalloutMenuItem
}

type Props = {
  menuButtonSize?: MenuButtonSize
  items: MenuProps['items']
  scrollable?: boolean
  removePadding?: boolean
}

const CalloutMenu: FC<Props> & SubComponents = ({
  items,
  menuButtonSize = 'small',
  scrollable = false,
  removePadding = false,
}) => (
  <CalloutMenuContainer
    $menuButtonSize={menuButtonSize}
    data-testid="callout-menu-container"
    $scrollable={scrollable}
    items={items}
    $removePadding={removePadding}
  />
)

CalloutMenuItem.displayName = 'CalloutMenu.Item'
CalloutMenu.Item = CalloutMenuItem

export default CalloutMenu
