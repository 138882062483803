import { FC } from 'react'
import { Icon, IconName } from '..'
import { NavIconContainer } from './NavIcon.style'

type Props = {
  icon: IconName
  alt: string
  fillBackground?: boolean
  selected?: boolean
  width?: number
  height?: number
  hideTooltip?: boolean
  inheritColor?: boolean
}

const NavIcon: FC<Props> = ({
  icon,
  alt,
  selected = false,
  width = 24,
  height = 24,
  hideTooltip,
  inheritColor = false,
}) => (
  <NavIconContainer selected={selected}>
    <Icon
      icon={icon}
      color="black"
      alt={alt}
      viewbox={`0 0 ${width} ${height}`}
      hideTooltip={hideTooltip}
      inheritColor={inheritColor}
    />
  </NavIconContainer>
)

export default NavIcon
