import { FC } from 'react'
import { Text } from '../index'
import { CalloutMenuItemContainer } from './CalloutMenu.style'

export type CalloutMenuItemProps = {
  label: string
  onClick?: () => void
  renderIcon?: FC
  key?: string
}

const CalloutMenuItem: FC<CalloutMenuItemProps> = ({
  label,
  onClick,
  renderIcon: Icon,
  ...rest
}) => (
  <CalloutMenuItemContainer key={label} onClick={onClick} {...rest}>
    <Text>{label}</Text>
    {Icon && <Icon />}
  </CalloutMenuItemContainer>
)

export default CalloutMenuItem
