import { Layout as AntLayout, Menu as AntMenu, Divider } from 'antd'
import styled from 'styled-components'

const { Header: AntHeader } = AntLayout

export const Header = styled(AntHeader)`
  display: flex;
  justify-content: center;
  height: ${({ theme }) => theme.sizing.navbarHeight};
  background-color: ${({ theme }) => theme.colors.bgSecondary};
  color: ${({ theme }) => theme.colors.typePrimary};
  line-height: 1;
  padding: 0 2.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.architecture3};
`

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3.75rem;
`

export const ProfileText = styled.div`
  font-size: 1rem;
  font-weight: 700;
`

export const PrimaryNav = styled(AntMenu)`
  width: 100%;
  flex: 1;

  &.ant-menu-horizontal:not(.ant-menu-dark) {
    height: 100%;
    background-color: transparent;
    border: 0;
    min-width: 0;

    li {
      &.ant-menu-item {
        padding: 0;
        border: 0;
        margin: 0;
        top: 0;

        &:hover {
          border: 0;
        }
      }

      &.ant-menu-item a {
        height: 100%;
        padding: 0 1.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: none;
        color: ${({ theme }) => theme.colors.typePrimary};
      }

      &.ant-menu-item.ant-menu-item-selected {
        background-color: transparent;
      }

      &.ant-menu-item-active div {
        color: ${({ theme }) => theme.colors.actionDarkLink};
      }

      &.ant-menu-submenu-active > div {
        color: ${({ theme }) => theme.colors.actionDarkLink};
      }

      // Disable default antd highlighting
      &.ant-menu-item:hover::after,
      &.ant-menu-item::after,
      &.ant-menu-item-selected::after {
        border-bottom: none;
        transition: none;
      }
    }
  }
  /* collapsed menu icon if too many items */
  .ant-menu-overflow-item-rest {
    height: 100%;
    display: inline-flex;
    align-items: center;
  }

  .ant-menu-submenu-title {
    color: ${({ theme }) => theme.colors.typePrimary};
  }
`

export const PrimaryNavItem = styled.div`
  height: 100%;
  font-size: 1rem;
  font-weight: 700;
`

export const AccountMenuContainer = styled.div`
  height: 100%;
  position: relative;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
`

export const StyledDivider = styled(Divider)`
  height: 1.5rem;
  background-color: ${({ theme }) => theme.colors.typeDarkPrimary};
  margin: 0;
`

export const AccountButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-left: 1.5rem;
`
