import styled from 'styled-components'
import { ThemeColor } from '../../themes'

export const StyledAnchor = styled.a<{ $hoverColor: ThemeColor | undefined; $bold: boolean }>`
  color: ${({ theme }) => theme.colors.actionLink};
  font-weight: ${({ $bold }) => ($bold ? 'bold' : 'normal')};

  &:hover {
    color: ${({ $hoverColor, theme }) => theme.colors[$hoverColor || 'typePrimary']};
  }
`
