import { Dropdown, MenuProps } from 'antd'
import { FC } from 'react'
import { CalloutMenu, Icon, NextLink as Link } from '..'
import {
  AccountButtonContainer,
  AccountMenuContainer,
  ContentContainer,
  Header,
  LogoContainer,
  PrimaryNav,
  PrimaryNavItem,
  ProfileText,
  StyledDivider,
} from './Navbar.style'
import NavIcon from './NavIcon'

type NavbarLinkProps = {
  route: string
  displayText: string
}

const NavbarLink: FC<NavbarLinkProps> = ({ route, displayText, ...rest }) => {
  return (
    <PrimaryNavItem {...rest}>
      <Link href={route}>
        <div className="menu-hover-background">{displayText}</div>
      </Link>
    </PrimaryNavItem>
  )
}

type AccProps = {
  userDisplayName?: string | null
}

const AccountButtonContent: FC<AccProps> = ({ userDisplayName }) => {
  const icon = 'ChevronDown'
  const alt = 'Account menu icon'

  return userDisplayName ? (
    <>
      <ProfileText data-testid="account-menu-button">{userDisplayName}</ProfileText>
      <Icon icon={icon} alt={alt} inheritColor />
    </>
  ) : (
    <NavIcon icon={icon} alt={alt} fillBackground />
  )
}
type Props = {
  logoLink: string
  logout: () => void
  isAuthenticated: boolean
  getSelectedRouteName: () => string
  MenuContent?: FC
  userDisplayName?: string | null
  accountMenuOptions?: MenuProps['items']
  items: MenuProps['items']
}

type SubComponents = {
  NavbarLink: typeof NavbarLink
}

const Navbar: FC<Props> & SubComponents = ({
  logoLink,
  logout,
  isAuthenticated,
  getSelectedRouteName,
  items,
  MenuContent,
  userDisplayName,
  accountMenuOptions,
}) => {
  const dropdownMenuItems: MenuProps['items'] = [
    ...(accountMenuOptions || []),
    {
      label: (
        <CalloutMenu.Item
          key="Log Out"
          label="Log Out"
          onClick={logout}
          renderIcon={() => <Icon icon="LogOut" alt="Log out icon" ariaHidden />}
        />
      ),
      key: 'log out',
    },
  ]
  return (
    <Header>
      <ContentContainer>
        <LogoContainer>
          <Link href={logoLink} aria-label="Net Purpose">
            <img src="/images/netpurpose_logo_black.svg" height="16px" width="auto" />
          </Link>
        </LogoContainer>
        {isAuthenticated && (
          <>
            <PrimaryNav
              mode="horizontal"
              selectedKeys={[getSelectedRouteName()]}
              overflowedIndicator={<div>More...</div>}
              items={items || []}
            />
            <AccountMenuContainer>
              {MenuContent && <MenuContent />}
              <StyledDivider type="vertical" />
              <Dropdown
                dropdownRender={() => (
                  <CalloutMenu menuButtonSize="large" items={dropdownMenuItems} />
                )}
                trigger={['click']}
                placement="bottomRight"
              >
                <AccountButtonContainer>
                  <AccountButtonContent userDisplayName={userDisplayName || null} />
                </AccountButtonContainer>
              </Dropdown>
            </AccountMenuContainer>
          </>
        )}
      </ContentContainer>
    </Header>
  )
}

NavbarLink.displayName = 'Navbar.NavbarLink'
Navbar.NavbarLink = NavbarLink

export default Navbar
